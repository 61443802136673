
.container {
  display: flex; /* or inline-flex */
  flex-wrap: wrap; /* This is the key line to wrap items */

  column-gap: 7px;
  row-gap: 7px;
}

/*

ul {
  list-style: none;
}

ul li {
  margin-bottom: 25px;
}

*/

